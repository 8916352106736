import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import PrivateRoute from './scenes/components/PrivateRoute';
import asyncComponent from './scenes/components/hoc/asyncComponent';

const Login = from2('./scenes/login.form');
const Register = from2('./scenes/register.form');
const Profile = from2('./scenes/profile.form');
const IzdelkiAdmin = from2('./scenes/trgovina/izdelki.form');
const IzdelekEdit = from2('./scenes/trgovina/izdelekedit.form');
const IzdelkiPub = from2('./scenes/trgovina/izdelkipub.form');
const Narocila = from2('./scenes/trgovina/narocila.form');
const MojaNarocila = from2('./scenes/trgovina/mojanarocila.form');
const PozabljenoGeslo = from2('./scenes/forgotpassword.form');
const ResetPassword = from2('./scenes/resetpassword.form');

function from2(path) {
  return asyncComponent(() => {
    return import(`${path}`);
  });
}

export default () => (
  <Switch>
    <PrivateRoute exact path="/" component={IzdelkiPub} />
    <Route path="/login" component={Login} />
    <Route path="/register" component={Register} />
    <Route path="/forgot-password" component={PozabljenoGeslo} />
    <Route path="/reset-password" component={ResetPassword} />

    <PrivateRoute path="/profile" component={Profile} />

    <PrivateRoute path="/izdelki" component={IzdelkiAdmin} roles={['admin']} />
    <PrivateRoute path="/izdelek" component={IzdelekEdit} roles={['admin']} />
    <PrivateRoute path="/izdelkipub" component={IzdelkiPub} roles={['admin', 'tehnik']} />
    <PrivateRoute path="/narocila" component={Narocila} roles={['admin']} />
    <PrivateRoute path="/mojanarocila" component={MojaNarocila} roles={['admin', 'tehnik']} />

    <Redirect exact path="/users" to="/users/all" />
    <Route render={() => <Redirect to="/" />} />
  </Switch>
);